<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section
          class="row border-bottom items-center full-width q-py-xs q-pl-none"
      >
        <q-legend :label="$t('Expected Inventory')" text-class="text-h6"/>

        <search dense autoset is-expandable @submit="handleSearch"/>

        <q-space/>

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0
                  ? 'light-blue-9'
                  : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            size="sm"
            :color="this.isTableView? 'positive': 'dark'"
            :label="this.isTableView? 'Icon view': 'Table view'"
            class="q-mr-sm"
            no-caps
            @click="handleTableView"
        />

        <q-btn
            color="light-blue-9"
            size="sm" icon="add"
            :label="$t('Item')"
            @click="openList"
        />

      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
            :is-open="isOpenFilters"
            :options="{
              defaultFilter: serverParams.filter,
              fields: activatedFields,
              values: {},
            }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
        />
      </q-card-section>
      <q-card-section class="q-ma-none q-pa-none">
        <div v-if="!isTableView">
          <q-table
              style="height: calc(100vh - 130px)"
              class="sticky-header-table"
              row-key="id"
              :rows-per-page-label="$t('Rows per page')"
              :rows="acceptanceItems"
              :columns="columns"
              v-model:pagination="pagination"
              :loading="acceptanceItemsLoading"
              :filter="filter"
              :rows-per-page-options="[0, 25, 50, 100, 150, 200, 250 , 0]"
              virtual-scroll
              binary-state-sort
              flat
              @request="onRequest"
          >

            <template v-slot:loading>
              <q-inner-loading
                  showing
                  color="primary"
              />
            </template>

            <template v-slot:body="props">
              <expected-items-row
                  :data="props"
                  @add-custom-filter="handleAddCustomFilter"
                  @open="handleShowInfo"
              />
            </template>
          </q-table>
        </div>

        <div v-else>
          <q-table
              style="height: calc(100vh - 130px)"
              class="sticky-header-table  my-sticky-column-table"
              row-key="id"
              :rows-per-page-label="$t('Rows per page')"
              :rows="acceptanceItems"
              :columns="columnsTable"
              v-model:pagination="pagination"
              :loading="acceptanceItemsLoading"
              :filter="filter"
              :rows-per-page-options="[0, 25, 50, 100, 150, 200, 250 , 0]"
              virtual-scroll
              binary-state-sort
              separator="cell"
              flat
              @request="onRequest"
          >
            <template v-slot:loading>
              <q-inner-loading
                  showing
                  color="primary"
              />
            </template>

            <template v-slot:body="props">
              <expected-items-row-table-view
                  :data="props"
                  ref="expectedItemsTable"
                  @add-custom-filter="handleAddCustomFilter"
                  @delete="loadDefaultItems"
                  @copy="handleCopy"
                  @open="handleShowInfo"
              />
            </template>

            <!-- Add this template for custom header rendering -->
            <template v-slot:header="props">
              <q-tr :props="props">
                <!-- Loop through each column to add tooltips -->
                <q-th
                    v-for="col in props.cols"
                    :key="col.name"
                >
                  {{ col.label }}
                  <!-- Use q-tooltip to add tooltips -->


                  <!-- Customize the tooltip content for each column -->
                  <q-tooltip :offset="[5, 5]">
                    <div v-if=" col && col.name === 'batch'">
                      Batch number of the shipment of goods to the warehouse of the preparation center.
                    </div>

                    <div v-else-if="col && col.name === 'upcs'">
                      *Optional
                      <br>
                      If you are aware of the real barcode that will be on product package - you can specify it here.
                      <br>
                      NOTE: barcodes from Seller Central listings are not to be trusted!
                    </div>

                    <div v-else-if="col && col.name === 'trackingNumber'">
                      Specify inbound tracking number of the box/pallet that will arrive at our facility
                    </div>

                    <div v-else-if="col && col.name === 'msku'">
                      *Optional
                      <br>
                      If you are using multiple SKUs for the same ASIN you can specify which one to use
                    </div>

                    <div v-else-if="col.name === 'bundleDetails'">
                      In case we need to bundle your items into packs, please specify details here (e.g. 2-pack, 5-pack.
                      set of 2 etc.)
                      Otherwise - input 'None'
                    </div>

                    <div v-else-if="col && col.name === 'bundleType'">
                      Please select whether the items are already bundled by your supplier or not.
                      <br>
                      That way we would know we don't need to bundle your items if ASIN name has 'X-pack' of 'set of X'
                      in its name
                    </div>

                    <div v-else-if="col && col.name === 'toDoType'">
                      Specify fulfilment channel. Any shipment to a specific address would be considered as FBM channel
                    </div>


                    <div v-else-if="col.name === 'toDoNotes'">
                      If required, write a comment on the batch
                    </div>

                    <div v-else-if="col.name === 'supplies'">
                      *Optional
                      <br>
                      If you use IL, please specify Supplier that needs to be inputted during batch creation.
                    </div>

                    <div v-else-if="col.name === 'cost'">
                      *Optional
                      <br>
                      If you are using IL, please specify the ‘Cost per Unit’ during batch creation.
                    </div>
                    <div v-else-if="col.name === 'expectedItems'">
                      Specify the quantity of units sent to our location.
                      <br>
                      NOTE: if you order single units that needs to be bundled - specify SINGLE units quantity, NOT
                      bundle quantity!
                    </div>

                    <div v-else-if="col.name === 'quantityReal'">
                      DO NOT FILL
                      <br>
                      This column is updated by SPC team - we will update you on how many units were received
                    </div>

                    <div v-else-if="col.name === 'quantityPlace'">
                      The quantity of goods in stock at the given moment.
                    </div>

                    <div v-else-if="col.name === 'quantityShipped'">
                      Number of items shipped
                    </div>
                  </q-tooltip>
                </q-th>
              </q-tr>
            </template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>
  </div>
  <offer-modal ref="offerModal" @submit="handleOfferSubmit"/>
  <q-dialog
      v-model="isOpened"
      :maximized="true"
  >
    <products-grid
        v-if="isOpened"
        :options="options"
        @submit="handleSubmit"
        @close="closeList"
        @on-item-change="handleItemChange"
    />
  </q-dialog>
  <confirm-modal ref="confirmModal"/>
</template>

<script>
import Search from '@/apps/app/components/search/Search.vue'
import FiltersCollapse from '@/apps/app/components/filters/FilterCollapse.vue'
import TableMixin from '@/apps/app/components/global/TableMixin.vue'
import { buildQuery } from '@/apps/app/utils/query-utils'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ExpectedItemsRow from '@/apps/app/components/expected-items/ExpectedItemsRow.vue'
import OfferModal from '@/apps/app/components/modals/OfferModal.vue'
import ExpectedItemsRowTableView from '@/apps/app/components/expected-items/ExpectedItemsRowTableView.vue'
import ProductsGrid from '@/apps/app/components/products/ProductsGrid.vue'
import _ from 'lodash'
import ConfirmModal from '@/apps/app/components/confirm-modal/ConfirmModal.vue'

export default {
  name: 'ExpectedItems',
  components: {
    ConfirmModal,
    ProductsGrid,
    ExpectedItemsRowTableView,
    OfferModal,
    ExpectedItemsRow,
    FiltersCollapse,
    Search
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpened: false,
      products: [],
      updatedItems: {},
      totalItems: 0,
      isOpenFilters: false,
      filter: '',
      warehouse: null,
      customFilter: '',
      isTableView: true,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Item'),
          name: 'id',
          align: 'center'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'center'
        },
        {
          label: '',
          name: 'cost',
          align: 'center'
        },
        {
          label: this.$t('Bundle'),
          name: 'bundle',
          align: 'center'
        },
        {
          label: this.$t('TO DO'),
          name: 'todo',
          align: 'center'
        },
      ],
      columnsTable: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'center'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'center'
        },
        {
          label: this.$t('Date'),
          name: 'date',
          align: 'center'
        },
        {
          label: this.$t('UPC/EAN'),
          name: 'upcs',
          align: 'center'
        },
        {
          label: this.$t('To Do Type'),
          name: 'toDoType',
          align: 'center'
        },
        {
          label: this.$t('Tracking Number'),
          name: 'trackingNumber',
          align: 'center'
        },
        {
          label: this.$t('Marketplace'),
          name: 'marketplace',
          align: 'center'
        },
        {
          label: this.$t('Source'),
          name: 'source',
          align: 'center'
        },
        {
          label: this.$t('ASIN'),
          name: 'asin',
          align: 'center'
        },
        {
          label: this.$t('Amazon SKU'),
          name: 'msku',
          align: 'center'
        },
        {
          label: this.$t('Bundling details'),
          name: 'bundleDetails',
          align: 'center'
        },
        {
          label: this.$t('Already pre-bundled?'),
          name: 'bundleType',
          align: 'center'
        },
        {
          label: this.$t('To Do Notes'),
          name: 'toDoNotes',
          align: 'center'
        },
        {
          label: this.$t('Supplier ID'),
          name: 'extId',
          align: 'center'
        },
        {
          label: this.$t('External Date'),
          name: 'extDate',
          align: 'center'
        },
        {
          label: this.$t('Supplier'),
          name: 'supplies',
          align: 'center'
        },
        {
          label: this.$t('Sale price'),
          name: 'cost',
          align: 'center'
        },
        {
          label: this.$t('Expected Items'),
          name: 'expectedItems',
          align: 'center'
        },
        {
          label: this.$t('Quantity Received'),
          name: 'quantityReal',
          align: 'center'
        },
        {
          label: this.$t('Blocked'),
          name: 'blocked',
          align: 'center'
        },
        {
          label: this.$t('Booked'),
          name: 'booked',
          align: 'center'
        },
        {
          label: this.$t('Defected'),
          name: 'defected',
          align: 'center'
        },
        {
          label: this.$t('On Hand'),
          name: 'quantityPlace',
          align: 'center',
        },
        {
          label: this.$t('Sent'),
          name: 'quantityShipped',
          align: 'center'
        },
        {
          label: '',
          name: 'action',
          align: 'center'
        }
      ],
      activatedFields: [
        'id',
        'sku',
        'warehouse',
        'weight.to',
        'weight.from',
        'state==i',
        'createdByDocumentId',
        'acceptanceItem',
        'productOffer==i',
        'trackingNumber'
      ],
    }
  },
  mounted () {
    this.handleLoadUser()
    this.handleWarehouse()
  },
  unmounted () {
    this.setAcceptanceItems([])
  },
  computed: {
    ...mapGetters([
      'user',
      'acceptanceItems',
      'acceptanceItemsItemsNumber',
      'acceptanceItemsLoading',
      'hasBaseAccess',
      'user',
      'isSupervisior',
      'isAdministrator',
      'isEmployee',
    ]),
    options () {
      return {
        acceptance: true,
        hasBarcode: true,
        batch: true,
        isUnique: [],
        allShops: true,
        hasComments: true,
        noWarehouseFilter: true,
        onlyOneShop: true,
        allowNoOffer: true,
        item: null,
        defaultValues: {
          warehouse: this.warehouse,
        },
      }
    },
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ') + this.acceptanceItemsItemsNumber
          : this.$t('Filter')
    }
  },
  methods: {
    ...mapActions([
      'loadAcceptanceItems',
      'loadShop',
      'loadUserAccount',
    ]),
    ...mapMutations([
      'setAcceptanceItems'
    ]),
    handleWarehouse () {
      const query = {
        per_page: 250,
        page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      return this.$service.warehouse.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.warehouse = items[0]
            }

            return this.warehouse
          })
    },
    handleLoadUser () {
      this.loadUserAccount()
          .then(() => {
            this.loadDefaultItems()
          })
    },
    openList () {
      this.isOpened = true
    },
    closeList () {
      this.isOpened = false

      this.$emit('on-close-search', {})
    },
    handleItemChange (data) {
      this.isOpened = false
      let i = this.products.findIndex(x => x.id === data.item)

      this.products[i] = { ...this.products[i], ...data.product }
      this.products[i].isUpdated = true
    },
    handleSubmit (data) {
      this.products = this.convertProductsToItems(data.products, data.warehouse)

      this.save()
      return this.products
    },
    getDimensions (dimensions) {
      if (!dimensions) {
        return { x: 0, y: 0, z: 0 }
      }

      if (typeof dimensions === 'string') {
        return JSON.parse(dimensions)
      }

      return dimensions
    },
    convertProductsToItems (products, warehouse) {
      let productsResult = products.map(x => {
        const item = {
          ...x,
          originalSku: x.barcode,
          sku: x.barcode,
          price: 0,
          barcode: undefined,
          warehouse: warehouse?.id,
          dimensions: this.getDimensions(x.dimensions),
        }

        if (x._embedded?.productOffer) {
          item.productOffer = {
            id: x._embedded?.productOffer.id,
            shop: x._embedded?.shop?.id
          }

          if (x._embedded.productOffer.eav && x._embedded.productOffer.eav['integrations-amazon-offer-asin']) {
            let marketplace = x._embedded.productOffer.eav['integrations-amazon-offer-marketplace'] || 'ATVPDKIKX0DER'
            item.eav = {
              ['storage-acceptance-expected-item-ASIN']: x._embedded.productOffer.eav['integrations-amazon-offer-asin'][marketplace]
            }
          }
        }

        if (x.barcode === undefined) {
          if (x.sku) {
            item.sku = x.sku
          } else {
            item.sku = `S/I/PO${item._embedded.productOffer.id}*`
          }
        }

        if (item._embedded?.item) {
          item.id = item._embedded?.item?.id

          delete item._embedded.item
        } else {
          item.id = undefined
        }

        const foundedItem = this.products.find(i => {
          return i.id == item.id && i.originalSku == x.originalSku && (i._embedded.productOffer || {}).id == (item._embedded.productOffer || {}).id
        })

        // if (!foundedItem && item._embedded.productOffer) {
        //   item._embedded.productOffer = x
        //   item._embedded.productOffer.dimensions = this.getDimensions(item._embedded.productOffer.dimensions)
        // }

        // Check for difference between existed item and submited one
        // Clone founded item to convert it from proxy to object
        item.isUpdated = !foundedItem || !!foundedItem.isUpdated || JSON.stringify(item) !== JSON.stringify({ ...foundedItem })

        return item
      })

      return productsResult
    },
    save () {
      const items = this.getUpdatedItems().map(x => {
        return this.createItem(x)
      })

      if (items.length <= 0) {
        return Promise.resolve({ items: [] })
      }

      return this.$service.acceptanceItem.saveAll(items, 'new')
          .catch(err => {
            const data = {
              noCancel: true,
              okColor: 'negative',
              description: err.message
            }

            return this.$refs.confirmModal.show(data)
                .then(() => {
                  return true
                })
          })
          .finally(() => {
            this.isOpened = false
            this.onRequest({ pagination: {} })
          })
    },
    getUpdatedItems () {
      this.updatedItems[this.pagination.page] = this.products.filter(x => x.isUpdated)
      return Object.values(this.updatedItems).reduce((acc, items) => ([...acc, ...items]), [])
    },
    handleCopy (item) {
      let shop = ''
      if (item._embedded && item._embedded.productOffer && item._embedded.productOffer._embedded && item._embedded.productOffer._embedded.virtualShop) {
        shop = item._embedded.productOffer._embedded.virtualShop._links.self.href.split('/').pop()
      } else if (item._embedded && e._embedded.productOffer && item._embedded.productOffer._embedded && item._embedded.productOffer._embedded.shop) {
        shop = item._embedded.productOffer._embedded.shop._links.self.href.split('/').pop()
      }

      const itemToCopy = {
        price: item.price,
        purchasingPrice: item.purchasingPrice,
        quantityExpected: item.quantityExpected,
        eav: item.eav,
        batch: item.batch
            ? item.batch
            : undefined,
        expires: item.expires
            ? item.expires
            : undefined,
        itemsStatus: item.itemsStatus,
        state: item.state,
        sku: item.sku
            ? `${item.sku}`.trim()
            : undefined,
        trackingNumber: item.trackingNumber,
        warehouse: item?._embedded?.warehouse.id,
        comment: item.comment
            ? item.comment
            : undefined
      }

      if (shop) {
        itemToCopy.shop = shop
      }

      if (item._embedded?.productOffer?.id) {
        itemToCopy.productOffer = {
          id: item._embedded.productOffer.id,
          shop: shop
        }
      }
      return this.$service.acceptanceItem.saveAll([itemToCopy], 'new')
          .catch(err => {
            const data = {
              noCancel: true,
              okColor: 'negative',
              description: err.message
            }

            return this.$refs.confirmModal.show(data)
                .then(() => {
                  return true
                })
          })
          .finally(() => {
            this.isOpened = false
            this.onRequest({ pagination: {} })
          })
    },
    createItem (row) {
      let shop = row._embedded?.shop
          ? row._embedded.shop.id
          : row.shop

      if (!shop) {
        shop = row._embedded.productOffer?._embedded?.shop
        if (!shop) {
          shop = row._embedded.productOffer?._embedded?.virtualShop
        }

        if (shop) {
          if (shop.id) {
            shop = shop.id
          } else {
            shop = shop._links.self.href.split('/').pop()
          }
        }
      }

      const item = {
        price: row.price,
        purchasingPrice: row.purchasingPrice,
        quantityExpected: row.count,
        batch: row.batch
            ? row.batch
            : undefined,
        expires: row.expires
            ? row.expires
            : undefined,
        eav: {
          'storage-acceptance-item-user-created': this.user.id
        },
        itemsStatus: row.itemsStatus,
        state: row.state,
        sku: row.sku
            ? `${row.sku}`.trim()
            : undefined,
        trackingNumber: row.trackingNumber,
        warehouse: row.warehouse,
        comment: row.comment
            ? row.comment
            : undefined
      }

      if (row && row.eav && row.eav['storage-acceptance-expected-item-ASIN']) {
        item.eav = {
          ...item.eav,
          ['storage-acceptance-expected-item-ASIN']: row.eav['storage-acceptance-expected-item-ASIN']
        }
      }
      if (shop) {
        item.shop = shop
      }

      if (row._embedded?.productOffer?.id) {
        item.productOffer = {
          id: row._embedded.productOffer.id,
          shop: shop
        }
      }

      return item
    },
    handleTableView () {
      this.isTableView = !this.isTableView
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    openCloseFilters () {
      this.isOpenFilters = !this.isOpenFilters
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilters = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    handleAddCustomFilter (filter) {
      this.customFilter = filter
      return this.onRequest({ pagination: {} })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
            ? '*'
            : ':*'
      }
      // query['group'] = [{
      //   field: 'productOffer',
      //   type: 'field',
      // }];

      query['order-by'] = [{
        field: 'created',
        direction: 'desc',
        type: 'field'
      }]

      if (this.isSupervisior || this.isAdministrator || this.isEmployee) {
        if (query.filter) {
          query.filter.push({ type: 'eq', field: 'state', value: 'new' })
          query.filter.push({
            type: 'jsonboperator',
            subfield: 'storage-acceptance-item-user-created',
            field: 'eav',
            subtype: 'isnotnull',
            native: true
          })
        } else {
          query.filter = [
            { type: 'eq', field: 'state', value: 'new' },
            {
              type: 'jsonboperator',
              subfield: 'storage-acceptance-item-user-created',
              field: 'eav',
              subtype: 'isnotnull',
              native: true
            }
          ]
        }
      } else {
        if (query.filter) {
          query.filter.push({ type: 'eq', field: 'state', value: 'new' })
          query.filter.push({
            type: 'jsonboperator',
            subfield: 'storage-acceptance-item-user-created',
            field: 'eav',
            value: this.user.id,
            subtype: 'eq',
            native: true
          })
        } else {
          query.filter = [
            { type: 'eq', field: 'state', value: 'new' },
            {
              type: 'jsonboperator',
              subfield: 'storage-acceptance-item-user-created',
              field: 'eav',
              value: this.user.id,
              subtype: 'eq',
              native: true
            }
          ]
        }
      }
      if (this.customFilter) {
        query.filter.push(this.customFilter)
      }

      this.updateParams(query)
      return this.loadAcceptanceItems(this.serverParams)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
          })
          .catch(err => {
            if (err.message === 'No warehouses found') {
              this.isNeededWarehouse = true
            }
          })
    },
    handleShowInfo (item) {
      if (item._embedded.productOffer._embedded.virtualShop) {
        const shopID = item._embedded.productOffer._embedded.virtualShop._links.self.href.split('/').pop()

        const options = {
          warning: this.$t('All changes won\'t be affected on the already existing documents and received products!')
        }

        this.loadShop(shopID)
            .then(e => {
              const product = {
                ...item,
                ...item._embedded.productOffer,
                _embedded: {
                  shop: e
                }
              }
              this.$refs.offerModal.open(product, options)
            })
      } else {
        const shopID = item._embedded.productOffer._embedded.shop._links.self.href.split('/').pop()

        const options = {
          warning: this.$t('All changes won\'t be affected on the already existing documents and received products!')
        }

        this.loadShop(shopID)
            .then(e => {
              const product = {
                ...item,
                ...item._embedded.productOffer,
                _embedded: {
                  shop: e
                }
              }
              this.$refs.offerModal.open(product, options)
            })
      }
    },
    handleOfferSubmit (productOffer) {

      this.items = this.items.map(x => {
        if (x._embedded.productOffer && `${x._embedded.productOffer.id}` === `${productOffer.id}`) {
          return {
            ...x,
            _embedded: {
              ...x._embedded,
              productOffer
            }
          }
        }

        return x
      })
    },
  }
}
</script>

<style lang="sass">
.my-sticky-column-table
  /* specifying max-width so the example can
    highlight the sticky column on any browser window */
  /* bg color is important for th; just specify one */

  td:nth-child(2)
    background-color: #ffffff

  td:nth-child(2)
    position: sticky
    left: 0
    z-index: 1
</style>
